<template>
  <step-lesson-layout class="module2-part1-lesson3"
    :title="$t('module2.part1.lesson.interactionBlock.info2')">
    <template v-slot:content>
      <!-- CAS 2 -->
      <div class="infography-wrapper">
        <div class="illu-wrapper">
          <div
            ref="interaction"
            :class="{ hidden: !interactionStarted }"
            class="illu" v-html="mq.tabletMinus ? require('@/assets/module2/part1/cas2-mobile.svg?inline') : require('@/assets/module2/part1/cas2-desktop.svg?inline')"
          />
        </div>
      </div>

      <!-- END BLOCK -->
      <div class="block-wrapper">
        <div class="app-decoration-block end-block">
          <p class="decoration-block-content">
            {{ $t('module2.part1.lesson.endBlock') }}
          </p>
          <app-button-layout @click="goToNextStep">
            {{ $t('global.navigation.resume') }}
          </app-button-layout>
          <app-button-layout color="alternate-secondary" @click="resumeLater">
            {{ $t('global.navigation.resumeLater') }}
          </app-button-layout>
        </div>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import useTrainingUtils from '@/utils/useTrainingUtils'
import interactionAnimationMixin from '@/components/training/module2/part1/interactionAnimationMixin'

export default {
  name: 'Module2Part1Lesson',
  components: { AppButtonLayout, StepLessonLayout },
  inject: ['mq'],
  mixins: [interactionAnimationMixin],
  setup (props, context) {
    const { goToNextStep, resumeLater } = useTrainingUtils()
    return { goToNextStep, resumeLater }
  }
}
</script>

<style lang="scss" scoped>
.module2-part1-lesson3 {
  .infography-wrapper {
    text-align: center;
    margin-top: $space-s;
    .illu-wrapper {
      background-color: #00935d;
      border-radius: $radius;
    }
    .label {
      color: #00935d;
    }
    .illu-wrapper {
      padding: $space-l $space-s;
      .illu {
        &.hidden {
          visibility: hidden;
        }
      }
    }
  }
}
</style>
